import Component from 'vue-class-component';
import Booking from '../../models/Booking';
import Booker from '../../models/Booker';
import { BModal } from 'bootstrap-vue';
import { bookerService, bookingService, siteService, invoiceService, dictionaryHelper } from '@/main';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import Place from '../../models/Place';
import moment from 'moment/min/moment.min.js';
import BookingBasePage from '../../models/BookingBasePage';
import SearchPlaceType from '../../models/Search/PlaceType';
import { Grid } from '@progress/kendo-grid-vue-wrapper';
import Vue from 'vue';
import { Invoice } from '../../models/Invoice';
import BookerAddress from '../../models/BookerAddress';
import { $router } from '@/router';
import { InvoiceState } from '@/models/InvoiceState';

@Component
export default class BookingsPage extends BookingBasePage {
    public booking: Booking = {} as Booking;
    public booker: Booker = {} as Booker;
    public bookingStates: any[] = null;
    public travelTypes: any[] = [];
    public gridInvoices: any[] = [];
    public bookingButtonDisabled: boolean = false;

    public availablePlaceTypes: SearchPlaceType[] = [];
    public selectedPlaceType: SearchPlaceType = null;
    public cancellationPeriod: any = '';
    public invoicesGridUpdate: number = 1;
    public selectedProperty: string = 'selected';

    public licensePlates: string[] = [];
    public selectedOptions = [
        { id: 4, name: 'Bedlinnen' },
        { id: 5, name: 'Boodschappenpakket' },
    ];

    public cancellationPeriodOptions = [
        { id: 0, text: 'Geen extra kosten', value: 'NoFee' },
        { id: 1, text: 'Meer dan drie maanden van te voren (15%)', value: 'PlusThreeMonths' },
        { id: 2, text: 'Tussen twee en drie maanden (50%)', value: 'ThreeToTwoMonths' },
        { id: 3, text: 'Tussen twee en een maand (75%)', value: 'TwoToOneMonth' },
        { id: 4, text: 'Binnen een maand (90%)', value: 'WithinOneMonth' },
        { id: 5, text: 'Zelfde dag (100%)', value: 'SameDay' },
    ];

    public newInvoiceState: number = null;
    public InvoiceIdForStateChange: number = null;
    public newInvoiceStateAmount: string = '0';

    public gridColumns = [
        { field: 'selected', width: 60, locked: true, filterable: false, className: 'bg-white' },
        {
            field: 'invoiceId',
            cell: this.renderInvoiceLink,
            title: 'Factuurnummer',
        },
        { field: 'bookingId', title: 'Boekingnummer' },
        { field: 'totalAmount', title: 'Bedrag', cell: this.renderValuta },
        { title: 'Openstaand bedrag', cell: this.renderOpenstaandValuta },
        { field: 'invoiceDate', title: 'Datum', format: '{0:dd-MM-yyyy}' },
        { field: 'dueDate', title: 'Verloopdatum', cell: this.renderDueDate },
        { field: 'status', title: 'Status' },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: '150px' },
    ];

    public options = [
        { id: 1, name: 'Champagneontbijt' },
        { id: 2, name: 'Fietshuur' },
        { id: 3, name: '3-gangen diner' },
        { id: 4, name: 'Bedlinnen' },
        { id: 5, name: 'Boodschappenpakket' },
    ];

    public filteredOptions = [];

    public themeStyles: any = {};
    public attrs: any = [
        {
            isInline: true,
            key: 'today',
            dates: new Date(),
        },
    ];

    public $refs!: {
        bookerModal: BModal;
        cancelModal: BModal;
        invoicesGrid: Grid;
        invoiceStatusModal: BModal;
        paidModal: BModal;
        checkInModal: BModal;
    };

    private _totalPrice: number = 0;
    private _paidAmount: number = 0;
    public get totalBookingPrice() {
        return this._totalPrice;
    }

    public get fullyPaid() {
        return this._paidAmount === this._totalPrice;
    }

    public async mounted() {
        await this.initSite();
        this.travelTypes = this.getTravelTypes();
        await Promise.all([this.initBase(), this.initBoookingBase(), this.prepareInvoices(), this.getBookers()]);

        this.booking = await this.getBookingByKey(this.bookingKey);
        this.booking.mainBooker = this.getMainBooker();
        this.isLoading = false;

        this.filteredOptions = this.options;

        if (this.booking.bookingId) {
            const start = moment(this.booking.arrivalDate, 'YYYY-MM-DD').toDate();
            const end = moment(this.booking.departureDate, 'YYYY-MM-DD').toDate();

            if (this.booking.licensePlates) {
                this.licensePlates = this.booking.licensePlates;
            }
        }

        if (!this.isEmptyObject(this.$route.query) && this.bookingKey === 'aanmaken') {
            this.applyQueryStringInfo(this.$route.query);
        }
    }

    public applyQueryStringInfo(query: any) {
        if (query.accommodatieType && query.accommodatieType.length > 0) {
            this.addPlaceToBooking(query.accommodatieType);
        }

        if (query.accommodatie && query.accommodatie.length > 0) {
            const place = this.places.find((p) => p.placeId === query.accommodatie);
            this.addPlaceToBooking(place.placeTypeId, place);
        }
    }

    public async getPlaces() {
        const [err, response] = await to(siteService.getPlaces(this.site.siteKey));
        if (err) {
            this.showError('Mislukt om accommodaties op te halen');
        }
        return (this.places = response.data);
    }

    public async getBookingStates() {
        const [err, response] = await to(bookingService.getBookingStates());
        if (err) {
            this.showError('Mislukt om boekingstatussen op te halen');
        }
        return (this.bookingStates = response.data);
    }

    public async getBookers(): Promise<Booker[]> {
        return (this.bookers = (await bookerService.getBookers(this.site.siteId)).data);
    }

    public async prepareInvoices() {
        await this.getInvoices();

        this.gridInvoices = [];
        this._totalPrice = 0;
        this._paidAmount = 0;

        this.invoices.forEach((invoice: Invoice) => {
            this.gridInvoices.push({
                invoiceId: invoice.invoiceId,
                bookingId: invoice.bookingId,
                totalAmount: invoice.totalAmount,
                status: dictionaryHelper.get('InvoiceStates')[invoice.status],
                isDeleted: invoice.isDeleted,
                statusId: invoice.status,
                dueDate: invoice.dueDate ? moment(invoice.dueDate, 'YYYY-MM-DD').toDate() : null,
                invoiceDate: moment(invoice.invoiceDate, 'YYYY-MM-DD').toDate(),
                invoiceNumber: invoice.invoiceNumber,
                amountLeft: invoice.totalAmount - invoice.amountPaid,
                isMainBookingInvoice: invoice.isMainBookingInvoice,
            });

            this._totalPrice += invoice.totalAmount;
            this._paidAmount += invoice.amountPaid;
        });

        this.invoicesGridUpdate++;
    }

    public isPlaceTypeValid(placeTypeId) {
        // return this.searchResponse.ids.indexOf(placeTypeId) > -1;
    }

    public getPlaceTypeName(placeTypeId) {
        const placeType = this.placeTypes.find((type) => {
            return type.placeTypeId === placeTypeId;
        });

        return placeType.name;
    }

    public getPlaceName(placeId) {
        const filteredPlace = this.places.find((p) => {
            return placeId === p.placeId;
        });

        if (!filteredPlace) {
            return '';
        }

        return filteredPlace.name;
    }

    public getEmptyBooking(): Booking {
        return new Booking();
    }

    public async getBookingByKey(key: string) {
        const emptyBooking = this.getEmptyBooking();
        if (key === 'aanmaken') {
            this.editMode = true;
            return emptyBooking;
        } else {
            const [err, response] = await to<AxiosResponse<Booking>>(bookingService.getBooking(key, this.site.siteId));
            if (err) {
                this.showError('Boeking ophalen mislukt');
                return emptyBooking;
            }

            return new Booking(response.data);
        }
    }

    public async settleInvoices() {
        this.showPending('Facturen verrekenen...');

        const firstInvoiceId = this.getSelectedInvoices()[0].invoiceId;
        const secondInvoiceId = this.getSelectedInvoices()[1].invoiceId;

        const [err, response] = await to(invoiceService.settleInvoices(firstInvoiceId, secondInvoiceId, this.siteId));
        if (err) {
            return this.clearAndShowError('Facturen verekenen wijzigen mislult', err);
        }

        this.clearAndShowSuccess('Facturen succesvol verrekend.');

        await this.prepareInvoices();
        this.editMode = false;
    }

    public getSelectedInvoices() {
        return this.gridInvoices.filter((item) => item[this.selectedProperty] === true);
    }

    public onHeaderSelectionChange(event) {
        const checked = event.event.target.checked;
        Vue.set(
            this,
            'gridInvoices',
            this.gridInvoices.map((item) => ({ ...item, selected: checked })),
        );
    }

    public onSelectionChange(event) {
        Vue.set(event.dataItem, this.selectedProperty, !event.dataItem[this.selectedProperty]);
    }

    public createBooker(text) {
        const booker = new Booker();
        booker.emailAddress = text;
        booker.address = new BookerAddress();

        // this.editBooker = booker;
        this.$refs.bookerModal.show();
        return booker;
    }

    public getMainBooker(): Booker {
        const booker = this.bookers.find((b) => {
            return b.mainBookerId === this.booking.mainBookerId;
        });

        return new Booker(booker);
    }

    public selectMainBooker(id) {
        this.booking.mainBookerId = id;
    }

    public addBookerType(id) {
        const travelType = this.travelTypes.find((type) => {
            return type.type === id;
        });

        this.addTravelType(travelType);
    }

    public addTravelTypeToGroup(type) {
        const exists = this.booking.travelGroup.find((entry) => {
            return entry.travelGroupType === type.type;
        });

        if (!exists) {
            this.booking.travelGroup.push({
                travelGroupType: type.type,
                amountOfTravellers: 0,
            });
        }

        return this.booking.travelGroup;
    }

    public addTravelType(travelType) {
        const travelGroup = this.addTravelTypeToGroup(travelType);
        const entry = travelGroup.find((type) => {
            return type.travelGroupType === travelType.type;
        });
        const indexOf = travelGroup.indexOf(entry);
        travelGroup[indexOf].amountOfTravellers++;

        // reset the list to make sure vue always rerenders the guestTypes list
        this.booking.travelGroup = [];
        this.booking.travelGroup = travelGroup;
    }

    public substractTravelType(travelType) {
        const travelGroup = this.addTravelTypeToGroup(travelType);
        const entry = travelGroup.find((type) => {
            return type.travelGroupType === travelType.type;
        });
        const indexOf = travelGroup.indexOf(entry);
        if (travelGroup[indexOf].amountOfTravellers > 0) {
            travelGroup[indexOf].amountOfTravellers--;
        }

        // reset the list to make sure vue always rerenders the guestTypes list
        this.booking.travelGroup = [];
        this.booking.travelGroup = travelGroup;
    }

    public getTravelTypeFromBooking(type) {
        this.addTravelTypeToGroup(type);
        return this.booking.travelGroup.find((travelType) => {
            return travelType.travelGroupType === type.type;
        });
    }

    public totalTravellers() {
        let total = 0;
        this.booking.travelGroup.forEach((group) => {
            total += parseInt(group.amountOfTravellers, 10);
        });

        return total;
    }

    public deleteBookerType(index) {
        this.booking.travelGroup.splice(index, 1);
    }

    public addPlaceTypeToBooking(placeType) {
        this.selectedPlaceType = placeType;
        this.booking.placeTypeIds.push(placeType.placeTypeId);
    }

    public addPlaceToBooking(id, place?: Place) {
        // this.selectedPlaceType = this.searchResponse.accommodationTypes.find(type => type.placeTypeId === id);
        this.booking.placeIds.push(place ? place.placeTypeId : null);
    }

    public getPlaceLotNumber(placeId) {
        const place = this.places.find((p) => p.placeId === placeId);
        if (!place) {
            return '';
        }
        return place.lotNumber;
    }

    public removePlaceType(index) {
        this.booking.placeTypeIds.splice(index, 1);

        if (this.booking.placeTypeIds.length === 0) {
            this.bookingButtonDisabled = false;
        }
    }

    public getBookingId(booking: Booking): string {
        if (!booking.externalReservationIdentifier) {
            return booking.bookingId + '';
        }

        return `${booking.bookingId} / ${booking.externalReservationIdentifier}`;
    }

    public getBookingBooker(): Booker {
        const self = this;
        return this.bookers.find((booker: Booker) => {
            return booker.mainBookerId === self.booking.mainBookerId;
        });
    }

    public getBookingStatus(bookingStatus) {
        let stateSting = dictionaryHelper.get('BookingStates')[bookingStatus];

        if (bookingStatus === 6 && this.booking.checkedInDateTime) {
            stateSting += ` - ${moment
                .utc(this.booking.checkedInDateTime)
                .local(true)
                .format('dddd D MMMM YYYY, H:mm:ss')}`;
        }

        if (bookingStatus === 7 && this.booking.checkedOutDateTime) {
            stateSting += ` - ${moment
                .utc(this.booking.checkedOutDateTime)
                .local(true)
                .format('dddd D MMMM YYYY, H:mm:ss')}`;
        }

        return stateSting;
    }

    public getBookingSource(bookingSource: string) {
        if (!bookingSource || bookingSource.toLowerCase() === 'frontoffice') {
            return '';
        }

        return ` - Via ${bookingSource}`;
    }

    public openCancelModal() {
        this.$refs.cancelModal.show();
    }

    public async paymentRecievedBooking() {
        this.showPending('Boeking op status betaald zetten...');

        const [err, response] = await to(bookingService.paymentRecieved(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boekingstatus wijzigen mislult', err);
        }

        this.clearAndShowSuccess('Boeking succesvol op betaald gezet.');

        await this.prepareInvoices();

        this.booking.bookingState = 5;
        this.editMode = false;
    }

    public async save() {
        this.showPending('Boeking opslaan...');

        this.booking.siteId = this.site.siteId;
        this.booking.licensePlates = this.licensePlates;

        if (this.booking.bookingId) {
            const [err, response] = await to(bookingService.updateBooking(this.booking));
            if (err) {
                return this.clearAndShowError('Boeking updaten mislukt', err);
            }
        } else {
            const [err, response] = await to(bookingService.addBooking(this.booking, this.site.siteId));
            if (err) {
                return this.clearAndShowError('Boeking maken mislukt', err);
            }
        }

        this.clearAndShowSuccess('Boeking succesvol opgeslagen.');

        await this.prepareInvoices();
        this.editMode = false;
    }

    public async declineBooking() {
        this.showPending('Boeking afwijzen...');

        const [err, response] = await to(bookingService.declineBooking(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boeking afwijzen mislukt', err);
        }

        this.clearAndShowSuccess('Boeking succesvol afgewezen.');

        await this.prepareInvoices();
        this.booking.bookingState = 4;
        this.editMode = false;
    }

    public async cancelBooking() {
        this.showPending('Boeking annuleren...');

        const [err, response] = await to(bookingService.cancelBooking(this.booking.bookingId, this.site.siteId, this.cancellationPeriod.value));
        if (err) {
            return this.clearAndShowError('Boeking annuleren mislukt', err);
        }

        this.clearAndShowSuccess('Boeking succesvol geannuleerd.');

        await this.prepareInvoices();
        this.booking.bookingState = 8;
        this.editMode = false;
        this.$refs.cancelModal.hide();
    }

    public async acceptBooking() {
        this.showPending('Boeking accepteren...');

        const [err, response] = await to(bookingService.acceptBooking(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boeking accepteren mislukt', err);
        }

        this.clearAndShowSuccess('Boeking succesvol geaccepteerd.');
        await this.prepareInvoices();
        this.booking.bookingState = 3;
        this.editMode = false;
    }

    public async checkIn() {
        this.showPending('Boeking inchecken..');

        const [err, response] = await to(bookingService.checkIn(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boeking inchecken mislukt.', err);
        }

        this.clearAndShowSuccess('Boeking ingecheckt.');
        await this.prepareInvoices();
        this.booking.bookingState = 6;
        this.closeCheckinModal();
        this.editMode = false;
    }

    public async checkOut() {
        this.showPending('Checken of alles is betaald..');
        const invoices = await this.getInvoices(this.bookingKey);
        if (invoices.filter((x) => x.status < 4).length > 0) {
            this.clearAndShowWarning('Er zijn nog openstaande facturen. Bekijk de boekingdetails voor meer informatie.');
            return;
        }

        this.clearNotifications();
        this.showPending('Boeking uitchecken..');

        const [err, response] = await to(bookingService.checkOut(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boeking uitchecken mislukt.', err);
        }

        this.booking.bookingState = 7;
        await this.prepareInvoices();

        this.clearAndShowSuccess('Boeking uitgecheckt.');
        this.editMode = false;
    }

    public hasOpenInvoices() {
        return this.invoices.filter((invoice) => invoice.status === 1).length > 0;
    }

    public getBookedPlaceTypes() {
        const placeTypes = [];
        this.booking.places.forEach((placeObject) => {
            const placeInfo = { placeType: null, place: null, preferredPlace: null };

            placeInfo.placeType = this.getPlaceType(placeObject.placeTypeId);
            if (placeObject.placeId) {
                placeInfo.place = this.findPlace(placeObject.placeId);
                placeInfo.preferredPlace = placeObject.preferredPlace;
            }
            placeTypes.push(placeInfo);
        });

        return placeTypes;
    }

    public findPlace(placeId): Place {
        const place = this.places.find((obj) => {
            return obj.placeId === parseInt(placeId, 10);
        });

        return place || new Place();
    }

    public isEmptyObject(obj): boolean {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    public AddLicensePlate() {
        this.licensePlates.push('');
    }

    public openPaidModal() {
        this.$refs.paidModal.show();
    }

    public renderInvoiceLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'invoice',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, invoiceId: row.dataItem.invoiceId },
        });

        const props = { text: row.dataItem.invoiceNumber, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderDueDate(h: any, _, row: { dataItem: Invoice }) {
        if (row.dataItem.dueDate) {
            return h('td', moment(row.dataItem.dueDate).format('DD-MM-YYYY'));
        }

        return h('td', '-');
    }

    public showInvoiceStatusChangeModal(invoice) {
        this.InvoiceIdForStateChange = invoice.invoiceId;
        this.$refs.invoiceStatusModal.show();
    }

    public showCheckinModal() {
        this.$refs.checkInModal.show();
    }

    public closeCheckinModal() {
        this.$refs.checkInModal.hide();
    }

    public async changeInvoiceStatusWithModalHide() {
        const amoundPaid = this.newInvoiceState === 3 ? this.newInvoiceStateAmount : null;
        await this.makeInvoicePayment(this.InvoiceIdForStateChange, this.site.siteId, this.paymentMethod, amoundPaid);
        this.$refs.invoiceStatusModal.hide();
    }

    public async changeInvoiceStatus(invoiceId, siteId, newState): Promise<boolean> {
        this.showPending('Factuur updaten...');

        const [err, response] = await to(invoiceService.updateInvoiceState(invoiceId, siteId, newState));
        if (err) {
            this.clearAndShowError('Factuur updaten mislukt.', err);
            return false;
        }

        this.clearAndShowSuccess('Factuurstatus geupdatet.');
        await this.prepareInvoices();
        return true;
    }

    public async downloadInvoice(invoice) {
        const self = this;

        self.showPending('Factuur download voorbereiden...');

        const [err, response] = await to(invoiceService.downloadInvoice(invoice.invoiceId));
        if (err) {
            return self.clearAndShowError('Factuur downloaden mislukt.', err);
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.invoiceNumber}-${new Date().toLocaleTimeString()}.pdf`);
        document.body.appendChild(link);
        link.click();

        self.clearAndShowSuccess('Factuur gedownload.');
    }

    public async deleteInvoice(invoice) {
        this.showPending('Factuur verwijderen...');

        const [err, response] = await to(invoiceService.deleteInvoice(invoice.invoiceId));
        if (err) {
            return this.clearAndShowError('Factuur verwijderen mislukt.', err);
        }

        this.clearAndShowSuccess('Factuur verwijderd.');
        await this.prepareInvoices();
    }

    public async createCreditInvoice(invoice) {
        this.showPending('Factuur crediteren...');
        const [err, response] = await to(invoiceService.createCreditInvoice(invoice.invoiceId));
        if (err) {
            return this.clearAndShowError('Factuur credtieren mislukt.', err);
        }

        this.clearAndShowSuccess('Creditfactuur aangemaakt.');
        await this.prepareInvoices();
    }

    public async acceptBookingAndSendIdealLink() {
        await this.acceptBooking();
        await this.sendIdealLink(null);
        await this.prepareInvoices();
    }

    public async sendIdealLink(invoice: any) {
        const localInvoice = invoice ? invoice : this.invoices.find((inv) => inv.status === 1);
        const invoiceId = localInvoice.invoiceId;

        this.showPending('Betaal link genereren en versturen..');

        const [err, response] = await to(invoiceService.sendIdealLinkMail(invoiceId));
        if (err) {
            return this.clearAndShowError('Ideallink genereren en versturen is mislukt', err);
        }

        localInvoice.status = dictionaryHelper.get('InvoiceStates')[2];

        this.clearAndShowSuccess('Betaal link succesvol verstuurd.');
        this.editMode = false;
    }

    public async changeInvoiceState(invoiceId, siteId, newState) {
        this.showPending('Factuur updaten...');

        const [err, response] = await to(invoiceService.updateInvoiceState(invoiceId, siteId, newState));
        if (err) {
            return this.clearAndShowError('Factuur updaten mislukt.', err);
        }

        this.clearAndShowSuccess('Factuurstatus geupdatet.');
        await this.prepareInvoices();
    }

    public async makeInvoicePayment(invoiceId, siteId, paymentMethod, paidAmount?) {
        this.showPending('Factuurbetaling verwerken...');

        const [err, response] = await to(invoiceService.makeInvoicePayment(invoiceId, siteId, paymentMethod, paidAmount));
        if (err) {
            return this.clearAndShowError('Factuurbetaling mislukt.', err);
        }

        this.clearAndShowSuccess('Factuurbetaling geupdatet.');
        await this.prepareInvoices();
    }

    public async bookInvoice(invoice: Invoice) {
        const success = await this.changeInvoiceStatus(invoice.invoiceId, this.site.siteId, InvoiceState.Open);
        if (success) {
            invoice.status = 2;
        }

        await this.prepareInvoices();
    }

    public renderOpenstaandValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.amountLeft, 'c'));
    }

    public renderValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount, 'c'));
    }

    public newRegisterInvoice() {
        this.$router.push({
            name: 'cash-register',
            query: { bookingId: this.booking.bookingId.toString(), bookerId: this.booking.mainBookerId.toString() },
        });
    }

    public extendResidence() {
        const placeTypeIds = this.booking.places.map((x) => x.placeTypeId);
        const placeIds = this.booking.places.map((x) => x.placeId);

        const getTravellerCount = (travellerType) =>
            this.booking.travelGroup
                .filter((group) => group.travelGroupType === travellerType)
                .map((group) => group.amountOfTravellers)
                .reduce((p, c) => p + c, 0);

        this.$router.push({
            name: 'create-booking',
            query: {
                fromDate: moment(this.booking.departureDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                placeTypeIds: JSON.stringify(placeTypeIds),
                placeId: placeIds[0],
                mainBookerId: this.booking.mainBookerId.toString(),
                ZeroUntilTwoYears: getTravellerCount(1).toString(),
                ChildThreeYearsAndUp: getTravellerCount(2).toString(),
                Adult: getTravellerCount(3).toString(),
                Senior: getTravellerCount(4).toString(),
                Pet: getTravellerCount(5).toString(),
            },
        });
    }

    private renderActions(h: any, a, row) {
        const status = row.dataItem.statusId;
        const actions = [];

        if (status === 6) {
            return h('td');
        }

        if (status === 1) {
            // invoice of booking will be booked when function is triggerd
            actions.push({ title: 'Boek factuur', function: this.bookInvoice });
        }

        if (status === 1) {
            actions.push({ title: 'Verwijder factuur', function: this.deleteInvoice });
        }

        if (status >= 2 && status !== 6) {
            actions.push({ title: 'Download factuur als PDF', function: this.downloadInvoice });
        }

        if (status >= 2 && status <= 5) {
            actions.push({ title: 'Zet status (deels) betaald', function: this.showInvoiceStatusChangeModal });
        }

        if (status >= 2 && status <= 5 && !row.dataItem.isCreditInvoice) {
            actions.push({ title: 'Crediteer', function: this.createCreditInvoice });
        }

        if (status >= 2 && status <= 5 && status !== 4 && this.booking.source !== 'Qenner') {
            actions.push({ title: 'Stuur betaal link', function: this.sendIdealLink });
        }

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    private getTravelTypes(): any[] {
        const types = bookingService.getTravelGroupTypes();
        types.map((travelType) => {
            return (travelType.amount = 0);
        });

        return types;
    }
}
