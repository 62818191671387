export enum InvoiceState {
    'Unknown' = 0,
    'Concept' = 1,
    'Open' = 2,
    'PartiallyPaid' = 3,
    'Paid' = 4,
    'DueDateExpired' = 5,
    'Deleted' = 6,
    'Credited' = 7,
}
