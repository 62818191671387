import BasePage from '../../models/BasePage';
import Booker from '../../models/Booker';
import Component from 'vue-class-component';
import { bookerService, bookingService } from '@/main';
import to from 'await-to-js';
import Booking from '../../models/Booking';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';

@Component({
    mixins: [validationMixin],
    validations: {
        booker: {
            firstName: { required },
            lastName: { required },
            emailAddress: { required },
            dateOfBirth: { required },
            phoneNumber: { required },
            address: { required },
            city: { required },
            houseNumber: { required },
            postalCode: { required },
            streetName: { required },
        },
    },
})
export default class BookerEditPage extends BasePage {
    public booker: Booker = new Booker({ address: {} });
    public bookings: Booking[] = [];
    public invalid: boolean = false;

    public async mounted() {
        await this.initSite();
        const bookerKey = this.$route.params.bookerId;

        const [err, response] = await to(bookerService.getBooker(bookerKey, this.site.siteId));
        if (err) {
            return this.showError('Persoon ophalen mislukt');
        }

        this.booker = new Booker(response.data);

        const [bErr, bResponse] = await to(
            bookingService.getBookings(this.site.siteId, null, null, null, null, null, null, this.booker.mainBookerId),
        );
        if (bErr) {
            return this.showError('Historische boekingen ophalen mislukt');
        }

        this.bookings = bResponse.data;
        this.isLoading = false;
    }

    public getFullName() {
        return `${this.booker.firstName}${this.booker.insertion ? ' ' + this.booker.insertion : ''} ${this.booker.lastName}`;
    }

    public async save() {
        if (this.$v.booker.$invalid) {
            this.invalid = true;
            return this.clearAndShowWarning('Niet alle verplichte velden zijn ingevuld');
        }
        this.invalid = false;
        this.showPending('Boeker opslaan...');

        const [err, response] = await to(bookerService.updateBooker(this.booker, this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om boeker gegevens op te slaan.', err);
        } else {
            Object.assign(this.booker, response.data);
            this.clearAndShowSuccess('Boeker succesvol opgeslagen.');
            this.cancelEdit();
        }
    }
}
